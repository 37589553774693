@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url("./RefrigeratorDeluxe/RefrigeratorDeluxe.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url("./RefrigeratorDeluxe/RefrigeratorDeluxeBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url("./RefrigeratorDeluxe/RefrigeratorDeluxeExtraBold.otf") format("opentype");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url("./RefrigeratorDeluxe/RefrigeratorDeluxeHeavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url("./RefrigeratorDeluxe/RefrigeratorDeluxeLight.otf") format("opentype");
  font-weight: light;
  font-style: normal;
}
@font-face {
  font-family: "PUBGBattlegrounds";
  src: url("./PubgBattlegrounds/PUBGBattlegroundsRegular.otf") format("opentype"); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PUBGBattlegrounds";
  src: url("./PubgBattlegrounds/PUBGBattlegroundsTextured.otf") format("opentype"); 
  font-weight: normal;
  font-style: normal;
}
