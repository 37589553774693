@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@500&family=Poppins:wght@300;600&display=swap);
@import '../src/assets/fonts/Fonts.css';

.app__launcher {
  bottom: 100px !important;
}

.app__messenger {
  margin-bottom: 70px;
}
