@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@500&family=Poppins:wght@300;600&display=swap);
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url(/static/media/RefrigeratorDeluxe.4df05b88.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url(/static/media/RefrigeratorDeluxeBold.fc451348.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url(/static/media/RefrigeratorDeluxeExtraBold.4347ca89.otf) format("opentype");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url(/static/media/RefrigeratorDeluxeHeavy.9480277e.otf) format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "RefrigeratorDeluxe";
  src: url(/static/media/RefrigeratorDeluxeLight.44cbf15c.otf) format("opentype");
  font-weight: light;
  font-style: normal;
}
@font-face {
  font-family: "PUBGBattlegrounds";
  src: url(/static/media/PUBGBattlegroundsRegular.64c0961f.otf) format("opentype"); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PUBGBattlegrounds";
  src: url(/static/media/PUBGBattlegroundsTextured.46468edb.otf) format("opentype"); 
  font-weight: normal;
  font-style: normal;
}

.app__launcher {
  bottom: 100px !important;
}

.app__messenger {
  margin-bottom: 70px;
}

